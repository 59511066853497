@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "GilroyBold";
    src: url("../fonts/Gilroy/Gilroy-ExtraBold.otf");
}

@font-face {
    font-family: "GilroyLight";
    src: url("../fonts/Gilroy/Gilroy-Light.otf");
}

@font-face {
    font-family: "GilroyExtraBold";
    src: url("../fonts/Gilroy/Gilroy-ExtraBold.otf");
}

@font-face {
    font-family: "AvenirBook";
    src: url("../fonts/avenir/AvenirLTStd-Book.otf");
}