:root {
  --text-black: #101828;
  --text-light-gray: #667085;
}

a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}

#lola__body {
  background-color: #F9FAFB;
  width: 100%;
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

.rdt_Table{
  margin: 10px 0px;
}


.rdt_TableCol {
  background-color: #F9FAFB;
  font-family: 'GilroyBold';
  font-size: 14px;
}

.rdt_POS *{
  text-transform: capitalize;
}

.terminal-table {
  padding-top: 16px;
}

.terminal-table header {
  display: none;
}
